import './Footer.css';
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";


function Footer() {
  return (
    <div className="footer">
      <p className="copyright">
        Copyright &copy; Cursed Genes 2022
      </p>
      <a className="ig" href="https://www.instagram.com/cursed_genes/">
          <FontAwesomeIcon icon={faInstagram} size="2x" />
      </a>
    </div>
  );
}

export default Footer;